exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-styleguide-src-template-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-styleguide/src/template.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-styleguide-src-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teaser-js": () => import("./../../../src/pages/teaser.js" /* webpackChunkName: "component---src-pages-teaser-js" */),
  "component---src-templates-user-guides-js": () => import("./../../../src/templates/user-guides.js" /* webpackChunkName: "component---src-templates-user-guides-js" */)
}

